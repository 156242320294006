import React, {useMemo, useState} from 'react';
import { Checkbox, FormControlLabel, FormHelperText, Box } from '@mui/material';
import useUserStore from '../store/userStore';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {createAxiosWrapper} from "../Util";
import {host} from "../tableConfig";

const OrganicToggle: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { user, setUser } = useUserStore();
    const { getToken } = useKindeAuth()
    const axiosWrapper = useMemo(() => createAxiosWrapper(getToken), [getToken]);

    const handleOrganicChange = async (event: React.ChangeEvent<HTMLInputElement>) => {

        const checked = event.target.checked;
        setLoading(true);
        setError(null);
        try {
            const response = await axiosWrapper({
                url: `${host}/organic`,
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({ organicOnly: checked }),
            });
            console.log(response);
            if (!(response.data.result === 'success')) {
                throw new Error('Failed to update organic setting');
            }

            // Update the user store with the new organic setting
            setUser({ organicOnly: checked });
        } catch (err) {
            setError('Failed to update organic setting. Please try again.');
            // Revert the checkbox state on error
            setUser({ organicOnly: !checked });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box mt={2}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={user.organicOnly}
                        onChange={handleOrganicChange}
                        disabled={loading}
                        color="primary"
                    />
                }
                label="Show Organic Only"
            />
            {error && (
                <FormHelperText error>{error}</FormHelperText>
            )}
        </Box>
    );
};

export default OrganicToggle;