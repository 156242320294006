import React, {useState, useEffect} from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    TableSortLabel, Paper
} from '@mui/material';
import ItemDialog from './ItemDialog';
import {createAxiosWrapper} from './Util';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import useUserStore from "./store/userStore";

export interface Column {
    id: string;
    label: string;
    rows?: number;
    type?: string;
    dataType?: string;
    options?: readonly string[];
    display?: boolean;
    endpoint?: string;
    labelKey?: string;
    byClient?: boolean; // append the client id to the endpoint
}

interface Item {
    id: number;

    [key: string]: any;
}

interface Endpoints {
    get: string;
    post: string;
    put: string;
}

interface TablePanelProps {
    columns: readonly Column[];
    itemTitle: string;
    endpoints: Endpoints;
    showTimestamp?: boolean;
    timestampField?: string;
    filter?: string;
    filterType?: string;
    byClient?: boolean;
}

type Order = 'asc' | 'desc';

const TablePanel: React.FC<TablePanelProps> = ({
       columns,
       itemTitle,
       endpoints,
       showTimestamp = false,
       timestampField = 'timestamp',
       filter,
       filterType,
       byClient
   }) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<Item[]>([]);
    const [selectedItem, setSelectedItem] = useState<Item | undefined>();
    const [mode, setMode] = useState<'add' | 'view' | 'edit'>('add');
    const [selectOptions, setSelectOptions] = useState<{ [key: string]: any[] }>({});
    const [orderBy, setOrderBy] = useState<string>('');
    const [order, setOrder] = useState<Order>('asc');
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    // Get user and clients from the store
    const { user } = useUserStore();

    let displayColumns = columns.filter(column => column.display);
    if (showTimestamp) {
        displayColumns = [
            {id: 'formattedDate', label: 'Date', display: true},
            ...displayColumns
        ];
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchItems();
            await fetchSelectOptions();
        };
        fetchData();
    }, []);

    const fetchItems = async () => {

        try {
            let endpoint = endpoints.get;
            if (filter) {
                endpoint = `${endpoint}?filter=${filter}&filterType=${filterType}`;
            }
            if (byClient) {
                endpoint = `${endpoint}?clientId=${user?.clientId || 0}`;
            }
            const response = await axiosWrapper({url: endpoint})
            const formattedItems = response.data.data.map((item: Item) => ({
                ...item,
                formattedDate: item[timestampField] ? formatDate(new Date(item[timestampField])) : ''
            }));
            setItems(formattedItems);
        } catch (error) {
            console.error(`Error fetching ${itemTitle}s:`, error);
        }
    };

    const fetchSelectOptions = async () => {
        for (const column of columns) {
            if (column.type === 'select' && column.endpoint) {
                try {
                    let endpoint = column.endpoint;
                    if (filter) {
                        endpoint = `${endpoint}?filter=${filter}&filterType=${filterType}`;
                    }
                    const response = await axiosWrapper({url: endpoint});
                    if (response.data.result === 'success' && Array.isArray(response.data.data)) {
                        setSelectOptions(prev => ({
                            ...prev,
                            [column.id]: response.data.data,
                            // TODO refactor this if needed
                            //[column.label]: column.labelKey ? response.data.data[column.labelKey] : response.data.data
                        }));
                    }
                } catch (error) {
                    console.error(`Error fetching options for ${column.id}:`, error);
                }
            }
        }
    };

    const formatDate = (date: Date): string => {
        return date.toLocaleDateString('en-US', {month: 'short', day: 'numeric', year: 'numeric'});
    };

    const handleOpen = () => {
        setMode('add');
        setSelectedItem(undefined);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedItem(undefined);
        setMode('add');
    };

    const handleSave = async (newItem: Omit<Item, 'id'>) => {
        try {
            let endpoint;
            if (mode === 'add') {
                endpoint = endpoints.post;
                if (byClient) {
                    endpoint = `${endpoint}?clientId=${user?.clientId || 0}`;
                }
                await axiosWrapper({url: endpoint, data: newItem, method: 'post'});
            } else if (mode === 'edit' && selectedItem) {
                await axiosWrapper({url: `${endpoints.put}/${selectedItem.id}`, method: 'put', data: newItem});
            }
            await fetchItems();
            handleClose();
        } catch (error) {
            console.error(`Error saving ${itemTitle}:`, error);
            throw error;
        }
    };
    const handleDelete = async (item: Omit<Item, 'id'>) => {
        try {
            await axiosWrapper({ url: `${endpoints.put}/${item.id}`, method: 'delete' });
            await fetchItems();
            handleClose();
        } catch (error) {
            console.error(`Error saving ${itemTitle}:`, error);
            throw error;
        }
    };

    const handleRowClick = (item: Item) => {
        setSelectedItem(item);
        setMode('view');
        setOpen(true);
    };

    const handleModeChange = (newMode: 'add' | 'view' | 'edit') => {
        setMode(newMode);
    };

    const getDisplayValue = (item: Item, column: Column) => {
        if (column.type === 'radio' && column.options) {
            const optionIndex = Number(item[column.id]);
            return column.options[optionIndex] || item[column.id];
        } else if (column.type === 'select' && selectOptions[column.id]) {
            const option = selectOptions[column.id].find(opt => opt.id.toString() === item[column.id]?.toString());
            return option ? (option.brand_name || option.name || option.title || item[column.id]) : item[column.id];
        }
        return item[column.id];
    };

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedItems = React.useMemo(() => {
        const comparator = (a: Item, b: Item) => {
            const column = displayColumns.find(col => col.id === orderBy);
            if (!column) return 0;

            const aValue = getDisplayValue(a, column);
            const bValue = getDisplayValue(b, column);

            if (bValue < aValue) {
                return order === 'asc' ? 1 : -1;
            }
            if (bValue > aValue) {
                return order === 'asc' ? -1 : 1;
            }
            return 0;
        };

        return orderBy ? [...items].sort(comparator) : items;
    }, [items, order, orderBy, displayColumns]);

    return (
        <Box sx={{maxWidth: '600px', margin: '0 auto', paddingLeft: '12px', paddingRight: '12px'}}>
            <Box sx={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <Button
                    variant="contained"
                    onClick={handleOpen}
                    sx={{
                        marginBottom: '20px',
                        marginTop: '10px'
                    }}
                >
                    Add {itemTitle}
                </Button>
            </Box>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {displayColumns.map((column) => (
                                <TableCell key={column.id}
                                           sx={{fontWeight: 'bold'}}>
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={() => handleRequestSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedItems.map((item) => (
                            <TableRow key={item.id} onClick={() => handleRowClick(item)} style={{cursor: 'pointer'}}>
                                {displayColumns.map((column) => (
                                    <TableCell key={column.id}>
                                        {getDisplayValue(item, column)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ItemDialog
                open={open}
                onClose={handleClose}
                onSave={handleSave}
                onDelete={handleDelete}
                item={selectedItem}
                mode={mode}
                onModeChange={handleModeChange}
                columns={columns}
                itemTitle={itemTitle}
            />
        </Box>
    );
};

export default TablePanel;