export const menuConfig = [
    { title: "Home" },
    { title: "Inspections", main: true },
    { title: "Photos", main: true },
    { title: "In", main: true },
    { title: "Out", main: true },
    { title: "Batches", main: true },
    { title: "Inventory", main: true },
    { title: "Logs", main: true },
    { title: "Review", main: true },
    { title: "Tickets", main: true },
    { title: "Chat", main: true },
    { title: "Cards", main: true},
    { title: "Planning", main: true},
    { title: "Help Center" },
    { title: "Logout" },
    {
        title: "Other",
        subItems: [
            { title: "Recall" },
            { title: "Process Flow Menu" },
            { title: "Lot Tracking Menu" },
            { title: "Account" },
            { title: "Visitors" },
            { title: "Photo Capture" },
        ]
    },
    {
        title: "Ship+Receive",
        subItems: [
            { title: "Deliveries" },
            { title: "Ingredient Receiving" },
            { title: "Packaging Receiving" },
            { title: "Ingredients Inventory" },
            { title: "Products Inventory" },
            { title: "Shipping" }
        ]
    },
    {
        title: "Lists",
        subItems: [
            { title: "Ingredients" },
            { title: "Ingredient Suppliers" },
            { title: "Packaging" },
            { title: "Packaging Suppliers" },
            { title: "Distributors" },
            { title: "Products" },
            { title: "Scheduled Processes" },
            { title: "Brands" },
            { title: "Equipment" },
        ]
    },
    {
        title: "Manage Clients",
        subItems: [
            { title: "Account" },
            { title: "Clients" },
        ]
    },
];