import  { Column } from './TablePanel';
export const host = 'https://www.saltroadapp.com/sr-gmp';

const equipment = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        { id: 'make', label: 'Make' },
        { id: 'model', label: 'Model', display: true  },
        { id: 'location', label: 'Location', display: true },
        { id: 'serial_number', label: 'Serial Number' },
        {
            id: 'client',
            label: 'Owner (Client)',
            type: 'select',
            endpoint: `${host}/client`
        },
        { id: 'description', label: 'Description', rows: 4 },
        {
            id: 'power',
            label: 'Power',
            type: 'radio',
            options: ['Unknown', '120V', '240V', 'Gas', 'None'] as const
        },
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/getSQLResults/list_equipment`,
        post: `${host}/equipment`,
        put: `${host}/equipment`,
    }
};

const documents = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        { id: 'start_date', label: 'Start Date', dataType: 'date'},
        { id: 'end_date', label: 'Expiration Date', display: true, dataType: 'date'},
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/documents`,
        post: `${host}/documents`,
        put: `${host}/documents`,
    }
};

const deliveries = {
    columns: [
        {
            id: 'carrier',
            label: 'Carrier',
            type: 'radio',
            options: ['UPS', 'Fedex', 'Local', 'LTL', 'Other'] as const,
            display: true
        },
        { id: 'notes', label: 'Notes', rows: 4 },
        {
            id: 'client',
            label: 'Client',
            type: 'select',
            endpoint: `${host}/client`,
        }
    ] as const,
    endpoints: {
        get: `${host}/delivery`,
        post: `${host}/delivery`,
        put: `${host}/delivery`,
    }
};
const guides = {
    columns: [
        {
            id: 'carrier',
            label: 'Carrier',
            type: 'radio',
            options: ['UPS', 'Fedex', 'Local', 'LTL', 'Other'] as const
        },
        { id: 'notes', label: 'Notes', rows: 4 },
        {
            id: 'client',
            label: 'Client',
            type: 'select',
            endpoint: `${host}/client`,
            display: true
        }
    ] as const,
    endpoints: {
        get: `${host}/guide`,
        post: `${host}/guide`,
        put: `${host}/guide`,
    }
};
const procedures = {
    columns: [
        {
            id: 'carrier',
            label: 'Carrier',
            type: 'radio',
            options: ['UPS', 'Fedex', 'Local', 'LTL', 'Other'] as const
        },
        { id: 'notes', label: 'Notes', rows: 4 },
        {
            id: 'client',
            label: 'Client',
            type: 'select',
            endpoint: `${host}/client`,
            display: true
        }
    ] as const,
    endpoints: {
        get: `${host}/procedure`,
        post: `${host}/procedure`,
        put: `${host}/procedure`,
    }
};

const visitors = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        { id: 'company', label: 'Company' },
        { id: 'notes', label: 'notes', rows: 4 },
        {
            id: 'role',
            label: 'Role',
            type: 'radio',
            options: ['Contractor', 'Inspector', 'Applicant', 'Other'] as const
        }
    ] as const,
    endpoints: {
        get: `${host}/visitor`,
        post: `${host}/visitor`,
        put: `${host}/visitor`,
    }
};
const lists = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        {
            id: 'type',
            label: 'Interval',
            type: 'select',
            labelKey: 'title',
            endpoint: `${host}/getSQLResults/list_intervals`,
            display: true
        }
    ] as const,
    endpoints: {
        get: `${host}/list`,
        post: `${host}/list`,
        put: `${host}/list`,
    }
};

const list_items = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        {
            id: 'list_id',
            label: 'List',
            type: 'select',
            endpoint: `${host}/getSQLResults/lists`
        }
    ] as const,
    endpoints: {
        get: `${host}/list-item`,
        post: `${host}/list-item`,
        put: `${host}/list-item`,
    }
};


const ingredients = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        {
            id: 'organic', label: 'Organic',
            type: 'checkbox',
        },
        {
            id: 'metric',
            label: 'Metric',
            type: 'select',
            endpoint: `${host}/getSQLResults/metrics`,
            display: true
        },
        {
            id: 'category',
            label: 'Category',
            type: 'select',
            endpoint: `${host}/getSQLResults/ingredient_categories`,
            display: true
        },
        {
            id: 'kosher',
            label: 'Kosher Certification',
            type: 'radio',
            options: ['OU', 'OK', 'Star-K', 'KOF-K', 'IKC', 'Other'] as const
        },
        {id: 'ref_price', label: 'Reference Price'},
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/ingredient`,
        post: `${host}/ingredient`,
        put: `${host}/ingredient`,
    }
};
const myIngredients = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        {
            id: 'metric',
            label: 'Metric',
            type: 'select',
            endpoint: `${host}/getSQLResults/metrics`,
            display: true
        },
        {
            id: 'category',
            label: 'Category',
            type: 'select',
            endpoint: `${host}/getSQLResults/ingredient_categories`,
            display: true
        },
        {
            id: 'kosher',
            label: 'Kosher Certification',
            type: 'radio',
            options: ['OU', 'OK', 'Star-K', 'KOF-K', 'IKC', 'Other'] as const
        },
        {id: 'ref_price', label: 'Reference Price'},
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/getSQLResults/list_my_ingredients`,
    }
};

const ingredientReceiving = {
    columns: [
        {
            id: 'ingredient',
            label: 'Ingredient',
            type: 'select',
            endpoint: `${host}/ingredient`,
            display: true
        },
        {
            id: 'supplier',
            label: 'Supplier',
            type: 'select',
            endpoint: `${host}/ingredient-supplier`
        },
        { id: 'lot', label: 'Lot #' },
        { id: 'count', label: 'Count', display: true },
        { id: 'price', label: 'Price' },
        { id: 'invoice_num', label: 'Invoice Num' },
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/ingredient-receiving`,
        post: `${host}/ingredient-receiving`,
        put: `${host}/ingredient-receiving`,
    }
};
const packagingReceiving = {
    columns: [
        {
            id: 'item',
            label: 'Packaging Item',
            type: 'select',
            endpoint: `${host}/packaging`,
            display: true
        },
        {
            id: 'supplier',
            label: 'Supplier',
            type: 'select',
            endpoint: `${host}/packaging-supplier`
        },
        { id: 'count', label: 'Count', display: true },
        { id: 'price', label: 'Price' },
        { id: 'invoice_num', label: 'Invoice Num' },
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/packaging-receiving`,
        post: `${host}/packaging-receiving`,
        put: `${host}/packaging-receiving`,
    }
};
const shipments = {
    columns: [
        {
            id: 'product',
            label: 'Product',
            type: 'select',
            endpoint: `${host}/product`,
            display: true
        },
        {
            id: 'distributor',
            label: 'Distributor',
            type: 'select',
            endpoint: `${host}/distributor`
        },
        { id: 'count', label: 'Count', display: true },
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/shipment`,
        post: `${host}/shipment`,
        put: `${host}/shipment`,
    }
};
const invoices = {
    columns: [
        { id: 'notes', label: 'Notes', rows: 4, display: true },
    ] as const,
    endpoints: {
        get: `${host}/invoice`,
        post: `${host}/invoice`,
        put: `${host}/invoice`,
    }
};
const clients = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/client`,
        post: `${host}/client`,
        put: `${host}/client`,
    }
};
const logs = {
    columns: [
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/log`,
        post: `${host}/log`,
        put: `${host}/log`,
    }
};
const productCategories = {
    columns: [
        { id: 'name', label: 'Name', rows: 4, display: true },
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/product-category`,
        post: `${host}/product-category`,
        put: `${host}/product-category`,
    }
};
const products = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        {
            id: 'brand',
            label: 'Brand',
            type: 'select',
            endpoint: `${host}/brand`
        },
        {
            id: 'category',
            label: 'Category',
            type: 'select',
            display: true,
            byClient: true,
            endpoint: `${host}/product-category`,
        },
        {
            id: 'organic',
            label: 'Certified Organic',
            type: 'checkbox'
        },
        {
            id: 'kosher',
            label: 'Kosher Certification',
            type: 'radio',
            options: ['OU', 'OK', 'Star-K', 'KOF-K', 'IKC', 'Other'] as const
        },
        {
            id: 'packaging',
            label: 'Packaging',
            type: 'select',
            endpoint: `${host}/packaging`
        },
        {
            id: 'case_size',
            label: 'Case Size',
        },
        { id: 'upc', label: 'UPC'},
        { id: 'weight_or_volume', label: 'Weight/Vol (Number)'},
        {
            id: 'metric',
            label: 'Metric',
            type: 'select',
            endpoint: `${host}/getSQLResults/metrics`
        },
        { id: 'sku', label: 'SKU'},
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/product`,
        post: `${host}/product`,
        put: `${host}/product`,
    }
};

const tickets = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        { id: 'notes', label: 'Description', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/ticket`,
        post: `${host}/ticket`,
        put: `${host}/ticket`,
    }
};

const packaging = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        {
            id: 'category',
            label: 'Category',
            type: 'select',
            endpoint: `${host}/getSQLResults/packaging_categories`,
            display: true
        },
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/packaging`,
        post: `${host}/packaging`,
        put: `${host}/packaging`,
    }
};

const brands = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        {
            id: 'client',
            label: 'Client',
            type: 'select',
            endpoint: `${host}/getSQLResults/clients`,
            display: true
        },
    ] as const,
    endpoints: {
        get: `${host}/brand`,
        post: `${host}/brand`,
        put: `${host}/brand`,
    }
};

const ingredientSuppliers = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        { id: 'address', label: 'Address' },
        { id: 'zip', label: 'Zip Code'},
        { id: 'contact', label: 'Contact Name'},
        { id: 'phone', label: 'Phone'},
        { id: 'email', label: 'Email'},
        { id: 'products', label: 'Products', display: true},
        { id: 'notes', label: 'Notes', rows: 4 },
        { id: 'url', label: 'Website'},
    ] as const,
    endpoints: {
        get: `${host}/ingredient-supplier`,
        post: `${host}/ingredient-supplier`,
        put: `${host}/ingredient-supplier`,
    }
};
const packagingSuppliers = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        { id: 'address', label: 'Address' },
        { id: 'zip', label: 'Zip Code'},
        { id: 'contact', label: 'Contact Name'},
        { id: 'phone', label: 'Phone'},
        { id: 'email', label: 'Email'},
        { id: 'products', label: 'Products', display: true},
        { id: 'notes', label: 'Notes', rows: 4 },
        { id: 'url', label: 'Website'},
    ] as const,
    endpoints: {
        get: `${host}/packaging-supplier`,
        post: `${host}/packaging-supplier`,
        put: `${host}/packaging-supplier`,
    }
};
const incidents = {
    columns: [
        {
            id: 'incident',
            label: 'Incident Type',
            type: 'select',
            display: true,
            endpoint: `${host}/getSQLResults/incident_types`
        },
        { id: 'notes', label: 'Notes', rows: 4 },
    ] as const,
    endpoints: {
        get: `${host}/incident`,
        post: `${host}/incident`,
        put: `${host}/incident`,
    }
};

const distributors = {
    columns: [
        { id: 'name', label: 'Name', display: true },
        { id: 'address', label: 'Address' },
        { id: 'zip', label: 'Zip Code'},
        { id: 'contact', label: 'Contact Name'},
        { id: 'phone', label: 'Phone'},
        { id: 'email', label: 'Email'},
        { id: 'notes', label: 'Notes', rows: 4 },
        { id: 'url', label: 'Website'},
    ] as const,
    endpoints: {
        get: `${host}/distributor`,
        post: `${host}/distributor`,
        put: `${host}/distributor`,
    }
};

const config = {
    brands,
    clients,
    deliveries,
    distributors,
    documents,
    equipment,
    host,
    incidents,
    ingredientReceiving,
    ingredientSuppliers,
    ingredients,
    invoices,
    list_items,
    lists,
    logs,
    myIngredients,
    packaging,
    packagingReceiving,
    packagingSuppliers,
    productCategories,
    products,
    shipments,
    tickets,
    visitors
}

export default config

