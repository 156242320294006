import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'
import { useKindeAuth } from "@kinde-oss/kinde-auth-react"
import {uniq} from 'lodash'

import { ChartProvider } from './ChartContext'
import { menuConfig } from './menuConfig'
import LoginPanel from './LoginPanel'
import useUserStore from './store/userStore'
import getMenuItems, { MenuItem } from './menuItems'
import BottomTabBar from './BottomTabBar'
import InventoryManagement from './Inventory'
import SideMenu from './SideMenu'
import TopAppBar from './TopAppBar'
import { createAxiosWrapper } from "./Util"
import { host } from "./tableConfig"
import MainMenu from "./MainMenu";
import {ThemeWrapper} from "./ThemeWrapper";
import './App.css';

interface MenuItemWithSubItems extends MenuItem {
    subItems: MenuItem[];
}

function hasSubItems(item: MenuItem): item is MenuItemWithSubItems {
    return 'subItems' in item && Array.isArray((item as any).subItems);
}

export default function App(): JSX.Element {
    const [selectedItem, setSelectedItem] = useState<{ parentIndex: number | null; subIndex: number | null }>({ parentIndex: null, subIndex: null })
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
    const { login, register, logout, isAuthenticated, user } = useKindeAuth();
    const { getToken } = useKindeAuth()
    const axiosWrapper = useMemo(() => createAxiosWrapper(getToken), [getToken]);
    const [sideMenuItems, setSideMenuItems] = useState<MenuItem[]>([]);
    const { setUser, user: storeUser } = useUserStore();
    const [tabValue, setTabValue] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [filteredMenuItems, setFilteredMenuItems] = useState<MenuItem[]>([]);
    const filteredMenuItemsRef = useRef<MenuItem[]>([]);

    const logUserActionRef = useRef(async (action: string) => {});

    useEffect(() => {
        logUserActionRef.current = async (action) => {
            try {
                await axiosWrapper({
                    url: `${host}/log-action`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {action}
                });
                /*
                await axiosWrapper({
                    url: `${host}/chat/cleanup-duplicates`,
                    method: 'post'
                });
                */
            } catch (error) {
                console.error('Failed to log action:', error);
            }
        };
    }, [axiosWrapper]);

    // Update the ref whenever filteredMenuItems changes
    useEffect(() => {
        filteredMenuItemsRef.current = filteredMenuItems;
    }, [filteredMenuItems]);

    const getFilteredSideMenuItems = useCallback((items: MenuItem[]): MenuItem[] => {
        return items
            .filter(item => {
                // Always keep the Home button
                if ('isHome' in item) return true;

                // Check if this item is marked as main in menuConfig
                const configItem = menuConfig.find(config => config.title === item.title);
                if (configItem?.main) return false;

                // For items with subitems, keep them only if they have non-main subitems
                if (item.subItems) {
                    const filteredSubItems = item.subItems.filter(subItem => {
                        const parentConfig = menuConfig.find(config => config.title === item.title);
                        const subItemConfig:any = parentConfig?.subItems?.find(sub => sub.title === subItem.title);
                        return !subItemConfig?.main;
                    });

                    // Create a new item with filtered subitems
                    if (filteredSubItems.length > 0) {
                        return {
                            ...item,
                            subItems: filteredSubItems
                        };
                    }
                    return false;
                }

                // Keep non-main items
                return true;
            });
    }, [])

    const handleMenuAction = useCallback(async (action: string): Promise<void> => {
        await logUserActionRef.current(action)
        if (action === 'Home') {
            setSelectedItem({ parentIndex: null, subIndex: null });
            setIsDrawerOpen(false);
            return;
        }
        const menuPath: string[] = action.split('/');
        let currentItems: MenuItem[] = filteredMenuItemsRef.current;
        let parentIndex: number = -1;
        let subIndex: number = -1;

        for (let i: number = 0; i < menuPath.length; i++) {
            const index: number = currentItems.findIndex(item => item.title === menuPath[i]);
            if (index !== -1) {
                if (i === menuPath.length - 1) {
                    if (parentIndex === -1) {
                        parentIndex = index;
                    } else {
                        subIndex = index;
                    }
                } else {
                    if (hasSubItems(currentItems[index])) {
                        if (parentIndex === -1) {
                            parentIndex = index;
                        }
                        currentItems = (currentItems[index] as MenuItemWithSubItems).subItems;
                    } else {
                        break;
                    }
                }
            } else {
                break;
            }
        }

        if (parentIndex !== -1) {
            setSelectedItem({ parentIndex, subIndex: subIndex !== -1 ? subIndex : null });
            setIsDrawerOpen(false);
        }
    }, []);

    const handleNodeClick = useCallback((nodeName: string): void => {
        const nodeToMenuItemMap: { [key: string]: { title: string; subItem?: string } } = {
            'IngredientsList': { title: 'Lists', subItem: 'Ingredients By Client' },
            'PackagingList': { title: 'Lists', subItem: 'Packaging Types' },
            'BrandsList': { title: 'Lists', subItem: 'Brands' },
            'DistributorList': { title: 'Lists', subItem: 'Distributors' },
            'ProductsList': { title: 'Lists', subItem: 'Products' },
            'IngredientSupplier List': { title: 'Lists', subItem: 'Ingredient Suppliers' },
            'PackagingSupplier List': { title: 'Lists', subItem: 'Packaging Suppliers' },
            'Deliveries': {  title: 'Ship+Receive', subItem: 'Deliveries' },
            'IngredientsReceiving': { title: 'Ship+Receive', subItem: 'Ingredient Receiving' },
            'PackagingReceiving': { title: 'Ship+Receive', subItem: 'Packaging Receiving' },
            'IngredientsInventory': { title: 'Ship+Receive',subItem: 'Ingredient Inventory' },
            'PackagingInventory': { title: 'Ship+Receive',subItem: 'Product Inventory' },
            'Batches': { title: 'Batches' },
            'ScheduledProcesses': { title: 'Lists', subItem: 'Scheduled Processes' },
            'ProductInventory': { title: 'Product Counts' },
            'Shipments': { title: 'Ship+Receive', subItem: 'Shipping' },
            'Recalls': { title: 'Other', subItem: 'Recall' },
        };

        const menuItem = nodeToMenuItemMap[nodeName];
        if (menuItem) {
            handleMenuAction(`${menuItem.title}${menuItem.subItem ? '/' + menuItem.subItem : ''}`);
        }
    }, [handleMenuAction]);

    const allMenuItems = useMemo(() => {
        return getMenuItems(handleNodeClick, handleMenuAction);
    }, [handleNodeClick, handleMenuAction]);

    const handleTabChange = async (newValue: number): Promise<void> => {
        setTabValue(newValue);
        if (newValue === 0) {
            return setSelectedItem({ parentIndex: null, subIndex: null });
        }
        if (newValue === 1) {
            return handleMenuAction('Other/Process Flow Menu');
        }
        if (newValue === 2) {
            return handleMenuAction('Other/Lot Tracking Menu');
        }
        if (newValue === 3) {
            return handleMenuAction('Other/Account');
        }
    };

    const filterMenuItems = useCallback((menuItems: MenuItem[], clients: any[]): MenuItem[] => {
        const filterItem = (item: MenuItem): MenuItem | null => {
            // Filter logic for each item (including subitems)
            if (
                (item.title === "Manage Clients" && clients.length === 0) ||
                (item.title === "Visitor's Log" && clients.length === 0) // || (item.title === "Deliveries" && clients.length === 0)
            ) {
                return null;
            }

            // If the item has subitems, filter them recursively
            if (item.subItems && item.subItems.length > 0) {
                const filteredSubItems = item.subItems
                    .map(filterItem)
                    .filter((subItem): subItem is MenuItem => subItem !== null);

                return {
                    ...item,
                    subItems: filteredSubItems
                };
            }

            // If it passes all conditions and has no subitems, return the item as is
            return item;
        };

        // Apply the filter to all top-level items
        return menuItems
            .map(filterItem)
            .filter((item): item is MenuItem => item !== null);
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            if (isAuthenticated && user && isMounted) {
                setIsLoading(true);
                try {
                    let email = user.email;
                    //email = 'alfredsvegan@gmail.com';
                    const response = await axiosWrapper({url: `${host}/user?email=${email}`});
                    const data = response.data;

                    if (data.result === 'success' && isMounted) {
                        let userData = data.user;
                        let clients = data.clients;
                        setUser({
                            email: user.email || '',
                            given_name: userData.firstName || '',
                            family_name: userData.lastName || '',
                            organization: userData.clientName || '',
                            clientId: userData.clientId || '',
                            organicOnly: userData.organicOnly || '',
                            clientName: userData.clientName || '',
                            facility: userData.facility,
                            facility_num: userData.facility_num,
                            facility_name: userData.facility_name,
                            copacker: userData.copacker,
                            copacker_name: userData.copacker_name,
                            clients
                        });

                        // Filter menu items based on user data
                        const filteredItems = filterMenuItems(allMenuItems, clients);
                        setFilteredMenuItems(filteredItems);

                        // Create separate filtered items for the side menu
                        const sideMenuItems = getFilteredSideMenuItems(filteredItems);
                        setSideMenuItems(sideMenuItems); // Add this state variable

/*                        const c  = filteredItems.filter(item =>
                            menuConfig.find(configItem => configItem.title === item.title)?.main
                        )
                        console.log('C', c)*/

                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                } finally {
                    if (isMounted) {
                        setIsLoading(false);
                    }
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, [isAuthenticated, user, setUser, filterMenuItems, allMenuItems, axiosWrapper]);

    const mainMenuItems: MenuItem[] = useMemo(() => uniq(filteredMenuItems.filter(item =>
        menuConfig.find(configItem => configItem.title === item.title)?.main).filter(i => !i.subItems)
    ), [filteredMenuItems]);

    const getSelectedComponentTitle = (): string | null => {
        if (selectedItem.parentIndex === null) return null
        const item: MenuItem = filteredMenuItems[selectedItem.parentIndex]
        if (hasSubItems(item) && selectedItem.subIndex !== null) {
            return item.subItems[selectedItem.subIndex].title
        } else {
            return item.title
        }
    }

    const renderSelectedComponent = (): React.ReactElement | null => {
        if (selectedItem.parentIndex === null) return null
        const item: MenuItem = filteredMenuItems[selectedItem.parentIndex]
        if (hasSubItems(item) && selectedItem.subIndex !== null) {
            const subItem: MenuItem = item.subItems[selectedItem.subIndex];
            if (subItem.title === "Ingredient Counts" || subItem.title === "Product Counts") {
                return <InventoryManagement inventoryType={subItem.title === "Ingredient Counts" ? 'ingredient' : 'product'} />;
            }
            return React.cloneElement(subItem.component, {
                key: `${selectedItem.parentIndex}-${selectedItem.subIndex}`
            })
        }
        return React.cloneElement(item.component, {
            key: selectedItem.parentIndex
        })
    }

    return (
        <ThemeWrapper>
        <div className="App">
            {isAuthenticated ? (
                <ChartProvider>
                    <TopAppBar
                        onMenuClick={() => setIsDrawerOpen(true)}
                        onTitleClick={() => setSelectedItem({ parentIndex: null, subIndex: null })}
                        userName={storeUser?.given_name || null}
                        organization={storeUser?.organization || null}
                    />
                    <SideMenu
                        isOpen={isDrawerOpen}
                        onClose={() => setIsDrawerOpen(false)}
                        logout={logout}
                        handleNodeClick={handleNodeClick}
                        handleMenuAction={handleMenuAction}
                        menuItems={sideMenuItems}  // Use the filtered side menu items
                    />
                    <Box sx={{overflow: 'auto', height: 'calc(100vh - 137px)'}}>
                        {isLoading ? (
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px'}}>
                                <CircularProgress />
                            </Box>
                        ) : selectedItem.parentIndex === null ? (
                            <MainMenu
                                mainMenuItems={mainMenuItems}
                                handleMenuAction={handleMenuAction}
                            />
                        ) : (
                            <Box>
                                <Typography sx={{ textAlign: 'center', color: '#000', marginTop: '20px', marginBottom: '16px'}} variant="h4">
                                    {getSelectedComponentTitle()}
                                </Typography>
                                {renderSelectedComponent()}
                            </Box>
                        )}
                    </Box>
                    <BottomTabBar value={tabValue} onChange={handleTabChange} />
                </ChartProvider>
            ) : (
                <LoginPanel login={login} register={register}></LoginPanel>
            )}
        </div>
        </ThemeWrapper>
    )
}