import React, { useEffect, useState, useCallback } from 'react';
import { StreamChat } from 'stream-chat';
import {
    Chat,
    Channel,
    MessageList,
    MessageInput,
    Thread,
    Window,
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';
import {
    Paper,
    Box,
    CircularProgress,
    Alert,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItemButton,
    ListItemText,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    Message as MessageIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon
} from '@mui/icons-material';
import { host } from "../tableConfig";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { createAxiosWrapper } from "../Util";
import ChannelListWrapper from './ChannelListWrapper';

interface User {
    userId: string;
    userName: string;
}

const ChatApp = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [chatClient, setChatClient] = useState<StreamChat | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [openDM, setOpenDM] = useState(false);
    const [showChannelList, setShowChannelList] = useState(!isMobile);
    const [clientReady, setClientReady] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const { getToken } = useKindeAuth();
    const axiosWrapper = createAxiosWrapper(getToken);

    useEffect(() => {
        setShowChannelList(!isMobile);
    }, [isMobile]);

    const fetchUsers = useCallback(async () => {
        setLoadingUsers(true);
        try {
            const response = await axiosWrapper({
                url: `${host}/chat-users`,
                method: 'get',
            });
            setUsers(response.data.data);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to load users');
        } finally {
            setLoadingUsers(false);
        }
    }, []);

    useEffect(() => {
        if (openDM) {
            fetchUsers();
        }
    }, [openDM, fetchUsers]);

    useEffect(() => {
        let activeClient: StreamChat | null = null;
        let mounted = true;

        const initializeChat = async () => {
            if (activeClient) {
                await activeClient.disconnectUser();
                activeClient = null;
            }

            try {
                const response = await axiosWrapper({
                    url: `${host}/chat/init`,
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = response.data;

                if (!mounted) return;

                activeClient = new StreamChat(data.apiKey);

                const userData = {
                    id: data.userId,
                    name: `${data.firstName} ${data.lastName}`.trim(),
                    image: '/api/placeholder/40/40',
                };

                await activeClient.connectUser(userData, data.token);

                if (!mounted || activeClient.userID !== data.userId) return;

                const generalChannel = activeClient.channel('messaging', data.generalChannelId, {
                    configs: {
                        typing_events: true,
                        read_events: true,
                        connect_events: false
                    }
                });
                await generalChannel.watch();

                if (!mounted) return;

                setChatClient(activeClient);
                setClientReady(true);
                setIsConnected(true);
                setError(null);
            } catch (error) {
                console.error('Error initializing chat:', error);
                if (mounted) {
                    setError(error instanceof Error ? error.message : 'Failed to initialize chat');
                    setIsConnected(false);
                }
            }
        };

        initializeChat();

        return () => {
            mounted = false;
            if (activeClient) {
                const client = activeClient;
                setChatClient(null);
                setClientReady(false);
                setIsConnected(false);
                client.disconnectUser().then(() => {
                    if (activeClient === client) {
                        activeClient = null;
                    }
                });
            }
        };
    }, []);

    const createDirectMessage = async (selectedUserId: string, userName: string) => {
        if (!chatClient || !chatClient.userID) {
            console.error('Chat client not ready');
            setError('Chat client not ready');
            return;
        }

        try {
            // Generate the initial channelId
            const initialChannelId = [chatClient.userID, selectedUserId]
                .sort()
                .join('-');

            console.log('Creating/fetching DM channel:', initialChannelId);

            const response = await axiosWrapper({
                url: `${host}/chat/create-dm`,
                method: 'post',
                data: {
                    channelId: initialChannelId,
                    otherUserId: selectedUserId,
                    otherUserName: userName
                }
            });

            console.log('Backend response:', response.data);

            // Use the channelId from response
            const channel = chatClient.channel('messaging', response.data.channelId);
            await channel.watch();

            setOpenDM(false);
            if (isMobile) {
                setShowChannelList(false);
            }
        } catch (error) {
            console.error('Error creating DM:', error);
            setError('Failed to create direct message');
            if (error instanceof Error && error.message?.includes('connectUser')) {
                setIsConnected(false);
                setClientReady(false);
            }
        }
    };

    if (!chatClient || !clientReady || !isConnected) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="600px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="600px">
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    return (
        <Paper
            elevation={3}
            sx={{
                height: '600px',
                width: '100%',
                maxWidth: '1000px',
                margin: '0 auto',
                overflow: 'hidden',
                '.str-chat': {
                    height: '100%'
                },
                '.str-chat__message--system': {
                    display: 'none'
                },
                '.str-chat__channel-list': {
                    border: 'none',
                },
                '.str-chat__channel-list-messenger': {
                    border: 'none',
                },
                '.str-chat__channel-preview-messenger': {
                    border: 'none',
                },
                '.str-chat-channel-list': {
                    border: 'none',
                },
                '.str-chat__list': {
                    border: 'none',
                },
                '.str-chat__file-input-container': {
                    display: 'none !important'
                }
            }}
        >
            <Chat client={chatClient} theme="messaging light">
                <Box
                    display="flex"
                    height="100%"
                    sx={{
                        overflowX: 'auto',
                        minWidth: showChannelList ? { xs: 'calc(240px + 300px)', sm: '100%' } : '100%'
                    }}
                >
                    {showChannelList && (
                        <Box
                            sx={{
                                width: 240,
                                flex: '0 0 240px',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                borderRight: 1,
                                borderColor: 'divider',
                                '& .str-chat__channel-list-messenger': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        >
                            <Box p={1} display="flex" justifyContent="flex-end">
                                <IconButton
                                    onClick={() => setOpenDM(true)}
                                    color="primary"
                                    size="small"
                                >
                                    <MessageIcon />
                                </IconButton>
                            </Box>
                            <Box flexGrow={1} overflow="auto">
                                {chatClient && (
                                    <ChannelListWrapper
                                        client={chatClient}
                                        onMobileChannelClick={() => setShowChannelList(false)}
                                    />
                                )}
                            </Box>
                        </Box>
                    )}
                    <Box
                        flex={1}
                        position="relative"
                        sx={{
                            minWidth: { xs: '300px', sm: 0 }
                        }}
                    >
                        <Channel>
                            <Window>
                                <Box position="absolute" top={8} left={8} zIndex={2}>
                                    <IconButton
                                        onClick={() => setShowChannelList(!showChannelList)}
                                        size="small"
                                        sx={{
                                            '&:hover': {
                                                backgroundColor: 'transparent'
                                            },
                                            position: 'fixed',
                                            backgroundColor: 'white',
                                            border: '1px solid',
                                            borderColor: 'divider',
                                        }}
                                    >
                                        {showChannelList ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                    </IconButton>
                                </Box>
                                <MessageList />
                                <MessageInput />
                            </Window>
                            <Thread />
                        </Channel>
                    </Box>
                </Box>
            </Chat>

            <Dialog
                open={openDM}
                onClose={() => setOpenDM(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>Start Direct Message</DialogTitle>
                <DialogContent>
                    {loadingUsers ? (
                        <Box display="flex" justifyContent="center" p={2}>
                            <CircularProgress size={24} />
                        </Box>
                    ) : (
                        <List>
                            {users.map((user) => (
                                <ListItemButton
                                    key={user.userId}
                                    onClick={() => createDirectMessage(user.userId, user.userName)}
                                >
                                    <ListItemText
                                        primary={user.userName}
                                    />
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDM(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default ChatApp;