import React, { useState } from 'react';
import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Link,
    Collapse,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import ItemDetails from '../ItemDetails';
import { ScheduledProcess } from "../types";

interface ReadOnlyScheduledProcessPanelProps {
    process: ScheduledProcess;
    photos?: Array<{ id: number; url: string; name: string; }>;
}

const ReadOnlyScheduledProcessPanel: React.FC<ReadOnlyScheduledProcessPanelProps> = ({
                                                                                         process,
                                                                                         photos
                                                                                     }) => {
    const [previewImage, setPreviewImage] = useState<{ url: string; name: string } | null>(null);
    const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    photos = photos || process.photos;
    if (!photos) {
        photos = [];
    }

    const handleToggleDetails = (itemId: string) => {
        setExpandedItems(prev => ({
            ...prev,
            [itemId]: !prev[itemId]
        }));
    };

    const tableContainerStyles = {
        margin: isMobile ? '16px -24px' : '16px 0',
        width: isMobile ? 'calc(100% + 48px)' : '100%',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        '& .MuiTableRow-root:nth-of-type(odd)': {
            //backgroundColor: 'grey.100'
        },
        '& .MuiTable-root': {
            borderCollapse: 'collapse'
        }
    };

    const tableCellStyles = {
        ...(isMobile && {
            px: 2,
            '&:first-of-type': {
                pl: 3
            },
            '&:last-of-type': {
                pr: 3
            }
        })
    };

    const renderTable = (
        data: any[],
        columns: { field: string; align?: "left" | "right"; label: string; }[],
        tableType: 'ingredients' | 'packaging' | 'products'
    ) => (
        <TableContainer
            component={Paper}
            elevation={0}
            sx={tableContainerStyles}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell
                                key={index}
                                align={column.align || "left"}
                                sx={tableCellStyles}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((item, index) => (
                        <React.Fragment key={index}>
                            <TableRow>
                                {columns.map((column, colIndex) => (
                                    <TableCell
                                        key={colIndex}
                                        align={column.align || "left"}
                                        sx={tableCellStyles}
                                    >
                                        {column.field === 'name' ? (
                                            <Box>
                                                <Typography>{item[column.field]}</Typography>
                                                {tableType === 'products' && (
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        onClick={() => handleToggleDetails(item.id || index.toString())}
                                                        sx={{
                                                            textDecoration: 'none',
                                                            display: 'block',
                                                            textAlign: 'left',
                                                            mt: 0.5
                                                        }}
                                                    >
                                                        Details
                                                    </Link>
                                                )}
                                            </Box>
                                        ) : (
                                            item[column.field]
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                            {tableType === 'products' && (
                                <TableRow>
                                    <TableCell colSpan={columns.length} sx={{ p: 0 }}>
                                        <Collapse in={expandedItems[item.id || index.toString()]} timeout="auto">
                                            <ItemDetails
                                                itemType="products"
                                                itemId={item.id || index.toString()}
                                                isExpanded={expandedItems[item.id || index.toString()]}
                                            />
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    return (
        <Box>
            <Box>
                <Typography variant="h6">Client</Typography>
                <Typography variant="body1">{process.clientName}</Typography>
                <Typography variant="h6">Name</Typography>
                <Typography variant="body1">{process.name}</Typography>

                <Box sx={{ mt: 2, mb: 2}}>
                    <Typography sx={{fontWeight: 'bold' }}>
                        {process.organic ? 'Organic' : 'Non-Organic'}
                    </Typography>
                </Box>

                {photos.length > 0 && (
                    <>
                        <Typography variant="h6" sx={{ mt: 2 }}>Photos</Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginBottom: 2 }}>
                            {photos.map((photo) => (
                                <Box
                                    key={photo.id}
                                    sx={{
                                        position: 'relative',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setPreviewImage(photo)}
                                >
                                    <img
                                        src={'https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/' + photo.url}
                                        alt={photo.name}
                                        style={{
                                            width: 100,
                                            height: 100,
                                            objectFit: 'cover',
                                            borderRadius: 4
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                    </>
                )}
            </Box>

            <Dialog
                open={Boolean(previewImage)}
                onClose={() => setPreviewImage(null)}
                maxWidth={false}
                PaperProps={{
                    sx: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        position: 'relative'
                    }
                }}
            >
                <DialogContent sx={{ padding: 0, position: 'relative' }}>
                    <IconButton
                        onClick={() => setPreviewImage(null)}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#000',
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {previewImage && (
                        <img
                            src={'https://saltroad-gmp-photos.s3.us-east-1.amazonaws.com/' + previewImage.url.replace('-sm-', '-')}
                            alt={previewImage.name}
                            style={{
                                maxHeight: 'calc(100vh - 64px)',
                                maxWidth: '100%',
                                objectFit: 'contain'
                            }}
                        />
                    )}
                </DialogContent>
            </Dialog>

            <Box>
                <Typography variant="h6" sx={{ mt: 2 }}>Ingredients</Typography>
            </Box>
            {renderTable(
                process.ingredients || [],
                [
                    { field: 'name', label: 'Name' },
                    { field: 'count', label: 'Count', align: 'right' },
                    { field: 'metricName', label: 'Metric' }
                ],
                'ingredients'
            )}

            <Box>
                <Typography variant="h6" sx={{ mt: 2 }}>Packaging</Typography>
            </Box>
            {renderTable(
                process.packaging || [],
                [
                    { field: 'name', label: 'Name' },
                    { field: 'count', label: 'Count', align: 'right' }
                ],
                'packaging'
            )}

            <Box>
                <Typography variant="h6" sx={{ mt: 2 }}>Products</Typography>
            </Box>
            {renderTable(
                process.products || [],
                [
                    { field: 'name', label: 'Name' },
                    { field: 'count', label: 'Count', align: 'right' }
                ],
                'products'
            )}

            <Box>
                <Typography variant="h6" sx={{ mt: 2 }}>Quality Parameters</Typography>
                <ReactMarkdown>{process.qualityParams}</ReactMarkdown>

                <Typography variant="h6" sx={{ mt: 2 }}>Packaging Specifications</Typography>
                <ReactMarkdown>{process.packagingSpecs}</ReactMarkdown>

                <Typography variant="h6" sx={{ mt: 2 }}>Setup</Typography>
                <ReactMarkdown>{process.setup}</ReactMarkdown>

                <Typography variant="h6" sx={{ mt: 2 }}>Process</Typography>
                <ReactMarkdown>{process.process}</ReactMarkdown>

                <Typography variant="h6" sx={{ mt: 2 }}>Notes</Typography>
                <ReactMarkdown>{process.notes}</ReactMarkdown>
            </Box>
        </Box>
    );
};

export default ReadOnlyScheduledProcessPanel;