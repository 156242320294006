import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Select, MenuItem, InputLabel, FormControl, Button, useMediaQuery, useTheme, Box, Typography, Alert
} from '@mui/material';
import { ScheduledProcess, Ingredient, IngredientOption, MetricOption } from '../types';
import {host} from '../tableConfig'
import {createAxiosWrapper} from "../Util";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {Camera} from "@mui/icons-material";

interface AddScheduledProcessDialogProps {
    open: boolean;
    onClose: () => void;
    onSave: (process: Omit<ScheduledProcess, 'id' | 'clientName'>) => Promise<boolean>;
    clientName: string;
    clientId: number | '';
}

interface Client {
    id: number;
    name: string;
}

interface PackagingOption {
    id: number;
    name: string;
}

interface ProductOption {
    id: number;
    name: string;
}

const AddScheduledProcessDialog: React.FC<AddScheduledProcessDialogProps> = ({ open, onClose, onSave, clientName, clientId }) => {
    const [name, setName] = useState('');
    const [process, setProcess] = useState('');
    const [notes, setNotes] = useState('');
    const [qualityParams, setQualityParams] = useState('');
    const [setup, setSetup] = useState('');
    const [packagingSpecs, setPackagingSpecs] = useState('');
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [ingredientList, setIngredientList] = useState<IngredientOption[]>([]);
    const [metrics, setMetrics] = useState<MetricOption[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [packaging, setPackaging] = useState<{ id: number | null, count: number | null }[]>([]);
    const [packagingList, setPackagingList] = useState<PackagingOption[]>([]);
    const [products, setProducts] = useState<{ id: number | null, count: number | null }[]>([]);
    const [productList, setProductList] = useState<ProductOption[]>([]);
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const renderClientName = () => (
        <Box mb={2}>
            <Typography variant="subtitle2">Client</Typography>
            <Typography variant="body1">{clientName}</Typography>
        </Box>
    );

    const renderPhotoNote = () => (
        <Alert
            icon={<Camera className="h-5 w-5" />}
            severity="info"
            sx={{
                mt: 2,
                mb: 2,
                display: 'flex',
                alignItems: 'center',
                '& .MuiAlert-icon': {
                    mr: 1
                }
            }}
        >
            Photos can be added after saving the process.
        </Alert>
    );

    useEffect(() => {
        fetchIngredients();
        fetchMetrics();
        //fetchClients();
        fetchPackagingOptions();
        fetchProductOptions(clientId);
    }, [open]);

    const fetchIngredients = async () => {
        try {
            const response = await axiosWrapper({url: `${host}/ingredient`});
            const data = response.data;
            if (data.result === 'success') {
                setIngredientList(data.data);
            }
        } catch (error) {
            console.error('Error fetching ingredients:', error);
        }
    };

    const fetchMetrics = async () => {
        try {
            const response = await axiosWrapper({url: `${host}/getSQLResults/metrics`});
            const data = response.data;
            if (data.result === 'success') {
                setMetrics(data.data);
            }
        } catch (error) {
            console.error('Error fetching metrics:', error);
        }
    };

    const fetchPackagingOptions = async () => {
        try {
            const response = await axiosWrapper({url:`${host}/packaging`});
            const data = response.data;
            if (data.result === 'success') {
                setPackagingList(data.data);
            }
        } catch (error) {
            console.error('Error fetching packaging options:', error);
        }
    };

    const fetchProductOptions = async (client: number | '') => {
        try {
            const response = await axiosWrapper({url:`${host}/product?clientId=${client}`});
            const data = response.data;
            if (data.result === 'success') {
                setProductList(data.data);
            }
        } catch (error) {
            console.error('Error fetching product options:', error);
        }
    };

    const handleAddIngredient = () => {
        setIngredients([...ingredients, { id: null, name: '', metric: null, count: null }]);
    };

    const handleIngredientChange = (index: number, field: keyof Ingredient, value: string | number) => {
        const updatedIngredients = ingredients.map((ingredient, i) => {
            if (i === index) {
                switch (field) {
                    case 'id':
                        const selectedIngredient = ingredientList.find(item => item.id === Number(value));
                        return {
                            ...ingredient,
                            id: value === '' ? null : Number(value),
                            name: selectedIngredient ? selectedIngredient.name : ''
                        };
                    case 'metric':
                        return { ...ingredient, metric: value === '' ? null : Number(value) };
                    case 'count':
                        return { ...ingredient, count: value === '' ? null : Number(value) };
                    default:
                        return ingredient;
                }
            }
            return ingredient;
        });
        setIngredients(updatedIngredients);
    };

    const handleAddPackaging = () => {
        setPackaging([...packaging, { id: null, count: null }]);
    };

    const handlePackagingChange = (index: number, field: 'id' | 'count', value: string | number) => {
        const updatedPackaging = packaging.map((pack, i) => {
            if (i === index) {
                return {
                    ...pack,
                    [field]: value === '' ? null : Number(value)
                };
            }
            return pack;
        });
        setPackaging(updatedPackaging);
    };

    const handleAddProduct = () => {
        setProducts([...products, { id: null, count: null }]);
    };

    const handleProductChange = (index: number, field: 'id' | 'count', value: string | number) => {
        const updatedProducts = products.map((product, i) => {
            if (i === index) {
                return {
                    ...product,
                    [field]: value === '' ? null : Number(value)
                };
            }
            return product;
        });
        setProducts(updatedProducts);
    };

    const handleSave = async () => {
        const newProcess: Omit<ScheduledProcess, 'id' | 'clientName'> = {
            name,
            clientId: clientId as number,
            process,
            qualityParams,
            setup,
            packagingSpecs,
            notes,
            ingredients,
            packaging,  // Add packaging to the saved data
            products   // Add products to the saved data
        };
        const success = await onSave(newProcess);
        if (success) {
            // Reset form fields
            setName('');
            setProcess('');
            setQualityParams('');
            setSetup('');
            setPackagingSpecs('');
            setNotes('');
            setIngredients([]);
            setPackaging([]);
            setProducts([]);
            // Close the dialog
            onClose();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    margin: fullScreen ? '0' : '32px',
                    width: fullScreen ? '100%' : 'calc(100% - 64px)',
                    maxHeight: fullScreen ? '100%' : 'calc(100% - 64px)',
                },
            }}
        >
            <DialogTitle>Add Scheduled Process</DialogTitle>
            <DialogContent>
                {renderClientName()}
                <TextField
                    autoFocus
                    margin="dense"
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                {/* Ingredients Section */}
                {ingredients.map((ingredient, index) => (
                    <div key={index} style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Ingredient</InputLabel>
                            <Select
                                value={ingredient.id === null ? '' : ingredient.id}
                                onChange={(e) => handleIngredientChange(index, 'id', e.target.value as number)}
                            >
                                {ingredientList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl style={{ width: '190px', maxWidth: '190px' }}>
                            <InputLabel>Metric</InputLabel>
                            <Select
                                value={ingredient.metric === null ? '' : ingredient.metric}
                                onChange={(e) => handleIngredientChange(index, 'metric', e.target.value as number)}
                            >
                                {metrics.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Count"
                            type="number"
                            value={ingredient.count === null ? '' : ingredient.count}
                            onChange={(e) => handleIngredientChange(index, 'count', e.target.value)}
                            inputProps={{ step: "any" }}
                        />
                    </div>
                ))}
                <Button variant="contained"  onClick={handleAddIngredient} style={{ marginTop: '10px' }}>
                    Add Ingredient
                </Button>

                {/* Packaging Section */}
                {packaging.map((pack, index) => (
                    <div key={index} style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Packaging</InputLabel>
                            <Select
                                value={pack.id === null ? '' : pack.id}
                                onChange={(e) => handlePackagingChange(index, 'id', e.target.value as number)}
                            >
                                {packagingList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Count"
                            type="number"
                            value={pack.count === null ? '' : pack.count}
                            onChange={(e) => handlePackagingChange(index, 'count', e.target.value)}
                            inputProps={{ step: "any" }}
                        />
                    </div>
                ))}
                <Button  variant="contained" onClick={handleAddPackaging} style={{ marginTop: '10px' }}>
                    Add Packaging
                </Button>

                {/* Product Section */}
                {products.map((product, index) => (
                    <div key={index} style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                        <FormControl fullWidth>
                            <InputLabel>Product</InputLabel>
                            <Select
                                value={product.id === null ? '' : product.id}
                                onChange={(e) => handleProductChange(index, 'id', e.target.value as number)}
                            >
                                {productList.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Count"
                            type="number"
                            value={product.count === null ? '' : product.count}
                            onChange={(e) => handleProductChange(index, 'count', e.target.value)}
                            inputProps={{ step: "any" }}
                        />
                    </div>
                ))}
                <Button  variant="contained" onClick={handleAddProduct} style={{ marginTop: '10px' }}>
                    Add Product
                </Button>

                <TextField
                    sx={{ marginTop: '30px' }}
                    margin="dense"
                    label="Quality Parameters For Finished Product"
                    fullWidth
                    multiline
                    rows={4}
                    value={qualityParams}
                    onChange={(e) => setQualityParams(e.target.value)}
                />
                <TextField
                    sx={{ marginTop: '10px' }}
                    margin="dense"
                    label="Setup"
                    fullWidth
                    multiline
                    rows={4}
                    value={setup}
                    onChange={(e) => setSetup(e.target.value)}
                />
                <TextField
                    sx={{ marginTop: '10px' }}
                    margin="dense"
                    label="Packaging Specifications"
                    fullWidth
                    multiline
                    rows={4}
                    value={packagingSpecs}
                    onChange={(e) => setPackagingSpecs(e.target.value)}
                />

                <TextField
                    margin="dense"
                    label="Process"
                    fullWidth
                    multiline
                    rows={8}
                    value={process}
                    onChange={(e) => setProcess(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Notes"
                    fullWidth
                    multiline
                    rows={4}
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
                {renderPhotoNote()}
            </DialogContent>
            <DialogActions sx={{marginRight: '20px', marginLeft: '20px'}}>
                <Button variant="contained" color="secondary" onClick={onClose}>Close</Button>
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddScheduledProcessDialog;
