import React from 'react';
import { Box, Typography, Card, CardContent, Container } from '@mui/material';
// You can import the package.json like this in a vite/webpack project
import packageJson from '../package.json';

const About: React.FC = () => {
    return (
        <Container maxWidth="md">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    py: 0,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        mb: -2
                    }}
                >
                    <Typography
                        variant="h2"
                        component="h1"
                        sx={{
                            fontWeight: 'bold',
                            mb: 1,
                            background: 'linear-gradient(45deg, #1976d2, #42a5f5)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                        }}
                    >
                        Salt Road
                    </Typography>
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={{ mb: 3 }}
                    >
                        Version {packageJson.version}
                    </Typography>

                </Box>

                <Card
                    sx={{
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: theme => `0 0 20px ${theme.palette.primary.light}25`
                    }}
                >
                    <CardContent sx={{ p: 2 }}>

                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{
                                lineHeight: 1.8,
                                mb: 3
                            }}
                        >
                            This app is a set of simple tools for managing
                            production processes and documentation.
                        </Typography>

                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ lineHeight: 1.8 }}
                        >
                            At Salt Road, we are building tools to help small food producers
                            connect with each other and with their customers, and to manage
                            all aspects of their production processes.
                        </Typography>
                    </CardContent>
                </Card>

                <Box
                    sx={{
                        mt: 0,
                        textAlign: 'center',
                        color: 'text.secondary'
                    }}
                >
                    <Typography variant="body2">
                        © {new Date().getFullYear()} Salt Road. All rights reserved.
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
};

export default About;