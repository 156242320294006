import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    IconButton,
    TextField,
    Button,
    Snackbar,
    Alert,
} from '@mui/material';
import {
    PlayArrow as PlayArrowIcon,
    Pause as PauseIcon,
    Stop as StopIcon,
} from '@mui/icons-material';
import { createAxiosWrapper } from "../../Util";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { host } from "../../tableConfig";

interface TimerRowProps {
    batch: {
        id: number;
        batch_duration: number | null;
        package_duration: number | null;
    };
    title: string;
    timerType: string;
}

const TimerRow: React.FC<TimerRowProps> = ({ batch, title, timerType }) => {
    const [isRunning, setIsRunning] = useState(false);
    const [isStopped, setIsStopped] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [snackbar, setSnackbar] = useState<{
        open: boolean;
        message: string;
        severity: 'success' | 'error';
    }>({
        open: false,
        message: '',
        severity: 'success',
    });
    const { getToken } = useKindeAuth();

    // Initialize timer state based on batch data
    useEffect(() => {
        const existingDuration = timerType === 'batch'
            ? batch.batch_duration
            : batch.package_duration;

        if (existingDuration !== null) {
            setElapsedTime(existingDuration);
            setIsSaved(true);
            setIsStopped(true);
        }
    }, [batch, timerType]);

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const startTimer = () => {
        if (!isRunning && !isStopped) {
            const id = setInterval(() => {
                setElapsedTime(prev => prev + 1);
            }, 1000);
            setIntervalId(id);
            setIsRunning(true);
        }
    };

    const pauseTimer = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
            setIsRunning(false);
        }
    };

    const stopTimer = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setIsRunning(false);
        setIsStopped(true);
    };

    const saveTimer = async () => {
        setIsSaving(true);
        try {
            const axiosWrapper = createAxiosWrapper(getToken);
            const response = await axiosWrapper({
                url: `${host}/batch-timer`,
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: JSON.stringify({
                    timerType,
                    elapsedTime,
                    batchId: batch.id,
                }),
            });

            if (response.data.result === 'success') {
                setSnackbar({
                    open: true,
                    message: `${title} has been saved successfully.`,
                    severity: 'success',
                });
                setIsSaved(true);
            } else {
                throw new Error('Failed to save timer');
            }
        } catch (err) {
            setSnackbar({
                open: true,
                message: 'Failed to save timer. Please try again.',
                severity: 'error',
            });
        } finally {
            setIsSaving(false);
        }
    };

    const formatTime = (seconds: number) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const getSaveButtonStyles = () => {
        if (isSaved) {
            return {
                backgroundColor: '#4CAF50',
                '&:hover': {
                    backgroundColor: '#45a049',
                },
                '&.Mui-disabled': {
                    backgroundColor: '#4CAF50',
                    color: 'white',
                }
            };
        }
        return {};
    };

    return (
        <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                {title}
            </Typography>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
            }}>
                <IconButton
                    onClick={startTimer}
                    disabled={isRunning || isStopped || isSaved}
                    color="primary"
                    size="large"
                >
                    <PlayArrowIcon sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                    onClick={pauseTimer}
                    disabled={!isRunning || isStopped || isSaved}
                    color="primary"
                    size="large"
                >
                    <PauseIcon sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                    onClick={stopTimer}
                    disabled={isStopped || isSaved}
                    color="primary"
                    size="large"
                >
                    <StopIcon sx={{ fontSize: 28 }} />
                </IconButton>
                <TextField
                    value={formatTime(elapsedTime)}
                    size="small"
                    sx={{
                        width: 120,
                        backgroundColor: 'white'
                    }}
                    InputProps={{
                        readOnly: true,
                    }}
                />
                <Button
                    variant="contained"
                    onClick={saveTimer}
                    disabled={!isStopped || isSaving || isSaved}
                    sx={getSaveButtonStyles()}
                >
                    {isSaving ? 'Saving...' : isSaved ? 'Saved' : 'Save'}
                </Button>
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default TimerRow;