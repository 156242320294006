import React from 'react';
import {
    Box,
    Typography,
    Collapse,
    IconButton,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField
} from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield';
import IngredientCostPanel from "../IngredientCostPanel";
import { Ingredient } from '../batchTypes';
import HelpDialog, {HelpIconButton} from "./HelpDialog";
import { INGREDIENT_HELP_CONTENT } from './helpContent';

interface IngredientSectionProps {
    ingredients: Ingredient[];
    setIngredients: React.Dispatch<React.SetStateAction<Ingredient[]>>;
    processData: any;
    isExpanded: boolean;
    setIsExpanded: (value: boolean) => void;
}

const IngredientSection: React.FC<IngredientSectionProps> = ({
                                                                 ingredients,
                                                                 setIngredients,
                                                                 processData,
                                                                 isExpanded,
                                                                 setIsExpanded
                                                             }) => {
    const [helpOpen, setHelpOpen] = React.useState(false);
    const handleLotChange = (id: number, value: string) => {
        setIngredients(ingredients.map(ingredient =>
            ingredient.id === id ? { ...ingredient, lot: value } : ingredient
        ));
    };

    const handlePriceChange = (id: number, value: number | null) => {
        setIngredients(ingredients.map(ingredient =>
            ingredient.id === id ? { ...ingredient, price: value !== null ? Number(value) : 0 } : ingredient
        ));
    };

    return (
        <Box sx={{
            borderBottom: 1,
            borderColor: 'primary.main',
            backgroundColor: 'white',
        }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 3,
                    pb: isExpanded ? 2 : 3
                }}
                onClick={() => setIsExpanded(!isExpanded)}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                        cursor: 'pointer',
                    }}>
                    <Typography
                        variant="customH6">
                        Ingredient Info
                    </Typography>
                    <IconButton>
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
                <HelpIconButton onClick={(e) => {
                    e.stopPropagation(); // Prevent expand/collapse when clicking help
                    setHelpOpen(true);
                }} />
            </Box>
            <HelpDialog
                open={helpOpen}
                onClose={() => setHelpOpen(false)}
                content={INGREDIENT_HELP_CONTENT}
                title="Ingredient Info Section Help"  // optional
            />
            <Collapse in={isExpanded}>
                <TableContainer component={Paper} sx={{
                    boxShadow: 'none',
                    '& .MuiTable-root': {
                        borderTop: 1,
                        borderColor: 'divider'
                    }
                }}>
                    <Table>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: 'primary.main' }}>
                                <TableCell sx={{ color: 'white', fontWeight: 600 }}>Name</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 600 }}>Lot</TableCell>
                                <TableCell sx={{ color: 'white', fontWeight: 600 }}>Price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ingredients.map((ingredient) => (
                                <TableRow key={ingredient.id}>
                                    <TableCell>{ingredient.name}</TableCell>
                                    <TableCell>
                                        <TextField
                                            value={ingredient.lot}
                                            onChange={(e) => handleLotChange(ingredient.id, e.target.value)}
                                            size="small"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <CurrencyTextField
                                            value={ingredient.price}
                                            onChange={(event, value) => handlePriceChange(ingredient.id, value)}
                                            currencySymbol="$"
                                            decimalCharacter="."
                                            digitGroupSeparator=","
                                            size="small"
                                            textAlign="right"
                                            sx={{ backgroundColor: 'white' }}
                                        />
                                        <Typography variant="body2" sx={{ ml: 1, color: 'text.secondary', display: 'inline' }}>
                                            {ingredient.ref_metricName ? `(${ingredient.ref_metricName})` : ''}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ px: 3, py: 2 }}>
                    <Typography
                        variant="h6"
                        sx={{
                            color: 'primary.main',
                            fontWeight: 600,
                            mb: 2
                        }}>
                        Cost Analysis
                    </Typography>
                    <IngredientCostPanel
                        ingredients={ingredients}
                        processIngredients={processData.ingredients || []}
                    />
                </Box>
            </Collapse>
        </Box>
    );
};

export default IngredientSection;