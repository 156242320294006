import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

declare module '@mui/material/styles' {
    interface TypographyVariants {
        customH6: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        customH6?: React.CSSProperties;
    }
}
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        customH6: true;
    }
}

const theme = createTheme({
    typography: {
        fontFamily: 'ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
        button: {
            textTransform: 'none',
            fontWeight: 500,
        },
        h4: {
            fontSize: '24px'
        },
        h5: {
            fontSize: '16px'
        },
        customH6: {
            color: '#059669',
            fontSize: '20px',
            fontWeight: 600,
            marginTop: '-3px',
            flexGrow: 1,
        },
    },
    palette: {
        background: {
            default: '#f9fafb'
        },
        primary: {
            main: '#111827',
            light: '#374151',
            dark: '#111827',
        },
        text: {
            primary: '#111827',
            secondary: '#4b5563'
        }
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&.MuiSelect-select': {
                        fontSize: '14px',
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    maxWidth: '400px',
                },
                select: {
                    fontSize: '14px',
                    backgroundColor: 'white',
                    '&.MuiOutlinedInput-input': {
                        backgroundColor: 'white'
                    }
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    '& .MuiTableCell-head': {
                        fontSize: '14px',
                        fontWeight: 500,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center !important',
                    justifyContent: 'center !important',
                    textTransform: 'none',
                    borderRadius: '6px',
                    padding: '8px 16px',
                    fontSize: '14px',
                    fontWeight: 500,
                    boxShadow: 'none',
                    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
                        margin: 0,
                    },
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                contained: {
                    minWidth: '100px',
                    backgroundColor: '#059669', // Default green for primary actions
                    color: '#fff',
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': {
                        backgroundColor: '#047857', // Darker green on hover
                    },
                    '&.MuiButton-containedSecondary': { // Cancel button
                        backgroundColor: '#fff', // Red
                        border: '1px solid #ccc',
                        color: 'black',
                        '&:hover': {
                            //backgroundColor: '#dc2626', // Darker red
                        },
                    },
                    '&.MuiButton-containedError': { // Delete button
                        backgroundColor: '#dc2626', // Darker red
                        '&:hover': {
                            backgroundColor: '#b91c1c', // Even darker red
                        },
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#d1d5db',
                        color: '#9ca3af',
                    },
                },
                outlined: {
                    backgroundColor: '#4b5563',
                    color: '#fff',
                    border: 'none',
                    '&:hover': {
                        backgroundColor: '#374151',
                        borderColor: '#d1d5db',
                    },
                },
                text: {
                    color: '#111827',
                    '&:hover': {
                        backgroundColor: '#f9fafb',
                    },
                },
                sizeLarge: {
                    padding: '10px 20px',
                    fontSize: '1rem',
                },
                sizeSmall: {
                    padding: '6px 12px',
                    fontSize: '0.813rem',
                },
            },
            defaultProps: {
                disableElevation: true,
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-required:not(.Mui-focused):not(.Mui-filled)': {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#d32f2f'
                        }
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-required': {
                        // Make entire label red when empty/required
                        color: '#d32f2f',
                        // Return to normal color when filled
                        '&.Mui-focused, &.MuiFormLabel-filled': {
                            color: 'rgba(0, 0, 0, 0.6)' // or your desired color
                        }
                    },
                    asterisk: {
                        color: '#d32f2f'
                    }
                }
            }
        },
    },
});

export const ThemeWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Archivo:wght@600;700&display=swap" rel="stylesheet" />
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </>
    );
};