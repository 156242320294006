import React, { useState } from 'react';
import {
    Box,
    Typography,
    Collapse,
    IconButton,
    TextField,
    Button,
} from '@mui/material';
import {
    ExpandMore,
    ExpandLess,
    PlayArrow as PlayArrowIcon,
    Pause as PauseIcon,
    Stop as StopIcon,
} from '@mui/icons-material';
import { HelpIconButton } from "./HelpDialog";
import HelpDialog from "./HelpDialog";
import { TIMER_HELP_CONTENT } from './helpContent';
import TimerRow from "./TimerRow";

interface TimerSectionProps {
    batch: any;
    isExpanded: boolean;
    setIsExpanded: (value: boolean) => void;
}

const TimerSection: React.FC<TimerSectionProps> = ({
                                                       batch,
                                                       isExpanded,
                                                       setIsExpanded
                                                   }) => {
    const [helpOpen, setHelpOpen] = React.useState(false);

    return (
        <Box sx={{
            borderBottom: 1,
            borderColor: 'primary.main',
            backgroundColor: 'white',
        }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 3,
                    pb: isExpanded ? 2 : 3
                }}
                onClick={() => setIsExpanded(!isExpanded)}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexGrow: 1,
                        cursor: 'pointer',
                    }}>
                    <Typography variant="customH6">
                        Timers
                    </Typography>
                    <IconButton>
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
                <HelpIconButton onClick={(e) => {
                    e.stopPropagation(); // Prevent expand/collapse when clicking help
                    setHelpOpen(true);
                }} />
            </Box>
            <HelpDialog
                open={helpOpen}
                onClose={() => setHelpOpen(false)}
                content={TIMER_HELP_CONTENT}
                title="Timer Section Help"
            />
            <Collapse in={isExpanded}>
                <Box sx={{ px: 3, py: 2 }}>
                    <TimerRow batch={batch} timerType="batch" title="Total Batch Time" />
                    <TimerRow batch={batch} timerType="packaging" title="Packaging Time" />
                </Box>
            </Collapse>
        </Box>
    );
};

export default TimerSection;