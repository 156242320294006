import React from 'react';
import useUserStore from './store/userStore'
import { AppBar, Box, Typography, IconButton } from '@mui/material';
import { Menu } from 'lucide-react';

interface TopAppBarProps {
    onMenuClick: () => void;
    onTitleClick: () => void;
    userName: string | null;
    organization: string | null;
}

const TopAppBar: React.FC<TopAppBarProps> = ({
                                                 onMenuClick,
                                                 onTitleClick,
                                                 userName,
                                                 organization
                                             }) => {
    const { setUser, user: storeUser } = useUserStore();
    return (
        <AppBar
            position="static"
            elevation={2}
            sx={{
                backgroundColor: 'white',
                color: 'text.primary',
            }}
        >
            <Box
                onClick={onTitleClick}
                sx={{
                    maxWidth: '7xl',
                    mx: 'auto',
                    width: '100%',
                    px: {
                        xs: 2,
                        md: 4
                    },
                    py: {
                        xs: 1.5,
                        md: 3
                    },
                    cursor: 'pointer'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: {
                            xs: 2,
                            md: 4
                        }
                    }}>
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                onMenuClick();
                            }}
                            sx={{
                                color: 'text.primary',
                                p: {
                                    xs: 0,
                                    md: 1
                                },
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                            }}
                        >
                            <Menu size={24} />
                        </IconButton>
                        <Typography
                            variant="h5"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: {
                                    xs: '1.5rem',
                                    md: '1.5rem'
                                },
                            }}
                        >
                            SALT ROAD
                            <sup style={{
                                fontSize: '0.4em',
                                verticalAlign: 'super',
                                position: 'relative',
                                top: '-0.4em',
                            }}>®</sup>
                            {storeUser.organicOnly && (
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        color: 'text.secondary',
                                        mt: -0.5
                                    }}
                                >
                                    Organic
                                </Typography>
                            )}
                        </Typography>
                    </Box>

                    {userName && (
                        <Typography
                            variant="body2"
                            sx={{
                                color: 'text.secondary',
                                fontSize: {
                                    xs: '0.75rem',
                                    md: '0.875rem'
                                },
                                ml: 1
                            }}
                        >
                            {userName} | {organization}
                        </Typography>
                    )}
                </Box>
            </Box>
        </AppBar>
    );
};

export default TopAppBar;