import React from 'react';
import {
    Box,
    Typography,
    Grid,
    TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormData } from '../batchTypes';
import HelpDialog, {HelpIconButton} from "./HelpDialog";
import {SCHEDULED_PROCESS_DATES_CONTENT} from "./helpContent";

interface OtherDetailsSectionProps {
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const DatesSection: React.FC<OtherDetailsSectionProps> = ({
                                                                     formData,
                                                                     setFormData
                                                                 }) => {
    const [helpOpen, setHelpOpen] = React.useState(false);


    return (
        <Box sx={{ p: 3, backgroundColor: 'white' }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                px: 0,
                pt: 0,
                pb: 6
            }}>
                <Typography
                    variant="customH6">
                    Scheduling
                </Typography>
                <HelpIconButton onClick={() => setHelpOpen(true)} />
            </Box>
            <HelpDialog
                open={helpOpen}
                onClose={() => setHelpOpen(false)}
                content={SCHEDULED_PROCESS_DATES_CONTENT}
                title="Scheduled Process Dates"  // optional
            />
            <Grid container spacing={2} alignItems="center">

                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Scheduled"
                            value={formData.scheduledDate}
                            onChange={(newValue) => setFormData(prev => ({
                                ...prev,
                                scheduledDate: newValue
                            }))}
                            slotProps={{
                                textField: {
                                    sx: {
                                        width: '130px',
                                        backgroundColor: 'white'
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>

        </Box>
    );
};

export default DatesSection;