import React from 'react';
import {
    Box,
    Typography,
    Grid,
    TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormData } from '../batchTypes';
import HelpDialog, {HelpIconButton} from "./HelpDialog";
import {INGREDIENT_HELP_CONTENT, OTHER_DETAILS_HELP_CONTENT} from "./helpContent";
import CurrencyTextField from "@lupus-ai/mui-currency-textfield";

interface OtherDetailsSectionProps {
    batch: any,
    formData: FormData;
    setFormData: React.Dispatch<React.SetStateAction<FormData>>;
}

const OtherDetailsSection: React.FC<OtherDetailsSectionProps> = ({
                                                                     batch,
                                                                     formData,
                                                                     setFormData
                                                                 }) => {
    const [helpOpen, setHelpOpen] = React.useState(false);

    const handleChange = (field: keyof FormData) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setFormData(prev => ({
            ...prev,
            [field]: event.target.value
        }));
    };

    return (
        <Box sx={{ p: 3, backgroundColor: 'white' }}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                px: 0,
                pt: 0,
                pb: 6
            }}>
                <Typography
                    variant="customH6">
                    Other Details
                </Typography>
                <HelpIconButton onClick={() => setHelpOpen(true)} />
            </Box>
            <HelpDialog
                open={helpOpen}
                onClose={() => setHelpOpen(false)}
                content={OTHER_DETAILS_HELP_CONTENT}
                title="Other Details Section Help"  // optional
            />
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <TextField
                        type="number"
                        label="Labor Hours"
                        value={formData.laborHours}
                        onChange={handleChange('laborHours')}
                        inputProps={{
                            style: { textAlign: 'right' },
                            maxLength: 3
                        }}
                        sx={{
                            width: '90px',
                            backgroundColor: 'white'
                        }}
                    />
                </Grid>

                <Grid item>
                    <TextField
                        type="number"
                        label="Batch Count"
                        value={formData.count}
                        onChange={handleChange('count')}
                        inputProps={{
                            style: { textAlign: 'right' },
                            maxLength: 3
                        }}
                        sx={{
                            width: '90px',
                            backgroundColor: 'white'
                        }}
                    />
                </Grid>

                <Grid item>
                    <CurrencyTextField
                        label="Estimated Toll"
                        variant="outlined"
                        value={batch.estimated_toll}
                        onChange={(event, value) => {
                            if (batch && typeof value === 'number') {
                                batch.estimated_toll = value;
                            }
                        }}
                        currencySymbol="$"
                        decimalCharacter="."
                        digitGroupSeparator=","
                        size="medium"
                        textAlign="right"
                        inputProps={{
                            style: {
                                textAlign: 'right',
                                width: '60px'
                            }
                        }}
                        sx={{
                            backgroundColor: 'white',
                            width: '100px',
                            '& .MuiOutlinedInput-input': {
                                width: '40px',
                            },
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                },
                                '&:hover fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.87)',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: 'primary.main',
                                },
                            },
                            '& .MuiFormLabel-root': {
                                color: 'rgba(0, 0, 0, 0.6)',
                            },
                            '& .MuiFormLabel-root.Mui-focused': {
                                color: 'primary.main',
                            },
                        }}
                    />
                </Grid>

                <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Exp Date"
                            value={formData.expDate}
                            onChange={(newValue) => setFormData(prev => ({
                                ...prev,
                                expDate: newValue
                            }))}
                            slotProps={{
                                textField: {
                                    sx: {
                                        width: '130px',
                                        backgroundColor: 'white'
                                    }
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item>
                    <TextField
                        type="text"
                        label="Lot Code"
                        value={formData.lotCode}
                        onChange={handleChange('lotCode')}
                        sx={{
                            width: '170px',
                            backgroundColor: 'white'
                        }}
                    />
                </Grid>
            </Grid>

            <Box sx={{ mt: 2 }}>
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Output Notes"
                    value={formData.outputNotes}
                    onChange={handleChange('outputNotes')}
                    margin="normal"
                    sx={{ backgroundColor: 'white' }}
                />

                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Labor Notes"
                    value={formData.laborNotes}
                    onChange={handleChange('laborNotes')}
                    margin="normal"
                    sx={{ backgroundColor: 'white' }}
                />
            </Box>
        </Box>
    );
};

export default OtherDetailsSection;