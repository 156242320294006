// Table Panel with a select box for the client
import React, {useState, useEffect, useMemo} from 'react';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    TableSortLabel,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Typography, Paper
} from '@mui/material';
import {createAxiosWrapper} from '../Util';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import useUserStore from '../store/userStore';
import ItemDialogPlusPhotosAndNotes from "./ItemDialogPlusPhotosAndNotes";
import StatusSquare from "./StatusSquare";

export interface Column {
    id: string;
    dataType?: string;
    label: string;
    rows?: number;
    type?: string;
    options?: readonly string[];
    display?: boolean;
    endpoint?: string;
}

interface Item {
    id: number;

    [key: string]: any;
}

interface Client {
    id: number;
    name: string;
}

interface Endpoints {
    get: string;
    post?: string;
    put?: string;
}

interface TablePanelProps {
    columns: readonly Column[];
    itemTitle: string;
    endpoints: Endpoints;
    showTimestamp?: boolean;
    timestampField?: string;
    docType?: string;
    ro?: boolean;
    client?: any;
}

type Order = 'asc' | 'desc';

const TablePanelNew: React.FC<TablePanelProps> = ({
                                                             columns,
                                                             itemTitle,
                                                             endpoints,
                                                             showTimestamp = false,
                                                             timestampField = 'timestamp',
                                                             docType = 'Photo',
                                                             ro = false, // read only
                                                             client // don't use the user client, use this one (copacker or facility)
                                                         }) => {
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<Item[]>([]);
    const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
    const [selectedItem, setSelectedItem] = useState<Item | undefined>();
    const [mode, setMode] = useState<'add' | 'view' | 'edit'>('add');
    const [selectOptions, setSelectOptions] = useState<{ [key: string]: any[] }>({});
    const [orderBy, setOrderBy] = useState<string>('');
    const [order, setOrder] = useState<Order>('asc');
    const {getToken} = useKindeAuth()
    const axiosWrapper = createAxiosWrapper(getToken);
    const [selectedClientName, setSelectedClientName] = useState<string>('');

    // Get user and clients from the store
    const {user} = useUserStore();

    // Determine which special mode we're in, if any
    const isFacilityMode = client === 'facility' && user?.facility_num;
    const isCopackerMode = client === 'copacker' && user?.copacker;
    const isSpecialMode = isFacilityMode || isCopackerMode;

    // If in facility or copacker mode, treat as single client always
    const isSingleClient = isSpecialMode || (user?.clients?.length || 0) <= 1;

    // Get appropriate client list
    const clients = useMemo(() => {
        if (isFacilityMode) {
            return [{
                id: user.facility_num,
                name: user.facility_name || `Facility ${user.facility_num}`
            }];
        }
        if (isCopackerMode) {
            return [{
                id: user.copacker,
                name: user.copacker_name || `Copacker ${user.copacker}`
            }];
        }
        return user?.clients || [];
    }, [user?.clients, user?.facility_num, user?.facility_name, user?.copacker, user?.copacker_name, isSpecialMode]);

    useEffect(() => {
        if (isFacilityMode) {
            setSelectedClientId(user.facility_num);
            setSelectedClientName(user.facility_name || `Facility ${user.facility_num}`);
        } else if (isCopackerMode) {
            setSelectedClientId(user.copacker);
            setSelectedClientName(user.copacker_name || `Copacker ${user.copacker}`);
        } else if (isSingleClient && user?.clientId) {
            setSelectedClientId(user.clientId);
            setSelectedClientName(clients[0]?.name || user?.organization || '');
        }
    }, [
        isSingleClient,
        user?.clientId,
        user?.facility_num,
        user?.facility_name,
        user?.copacker,
        user?.copacker_name,
        clients,
        user?.organization,
        isSpecialMode
    ]);

    let displayColumns = columns.filter(column => column.display);
    if (showTimestamp) {
        displayColumns = [
            { id: 'formattedDate', label: 'Date', display: true },
            ...displayColumns
        ];
    }
    // Add status column if items have status
    if (items.length > 0 && 'status' in items[0]) {
        displayColumns = [
            ...displayColumns,
            { id: 'status', label: 'Status', display: true }
        ];
    }

    useEffect(() => {
        fetchSelectOptions();
    }, []);

    useEffect(() => {
        if (selectedClientId) {
            fetchItems();
        }
    }, [selectedClientId]);

    const fetchItems = async () => {
        if (!selectedClientId) return;
        try {
            let url = `${endpoints.get}?clientId=${selectedClientId}`
            if (isFacilityMode) {
                url = url + '&mode=facility'
            }
            const response = await axiosWrapper({url})
            const formattedItems = response.data.data.map((item: Item) => ({
                ...item,
                formattedDate: item[timestampField] ? formatDate(new Date(item[timestampField])) : ''
            }));
            setItems(formattedItems);
        } catch (error) {
            console.error(`Error fetching ${itemTitle}s:`, error);
        }
    };

    const fetchSelectOptions = async () => {
        for (const column of columns) {
            if (column.type === 'select' && column.endpoint) {
                try {
                    const response = await axiosWrapper({url: column.endpoint});
                    if (response.data.result === 'success' && Array.isArray(response.data.data)) {
                        setSelectOptions(prev => ({
                            ...prev,
                            [column.id]: response.data.data
                        }));
                    }
                } catch (error) {
                    console.error(`Error fetching options for ${column.id}:`, error);
                }
            }
        }
    };

    const formatDate = (date: Date | string): string => {
        const dateObj = typeof date === 'string' ? new Date(date) : date;
        if (isNaN(dateObj.getTime())) return '';
        return dateObj.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    };

    const handleOpen = () => {
        setMode('add');
        setSelectedItem(undefined);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedItem(undefined);
        setMode('add');
    };

    const handleSave = async (newItem: Omit<Item, 'id'>) => {
        try {
            let endpoint;
            if (mode === 'add') {
                // TODO decide if we need to add clientId to the post request, if in query string then we don't need to add to the server code
                endpoint = endpoints.post + `?clientId=${selectedClientId}`;
                await axiosWrapper({url: endpoint, data: {...newItem, client: selectedClientId}, method: 'post'});
            } else if (mode === 'edit' && selectedItem) {
                await axiosWrapper({
                    url: `${endpoints.put}/${selectedItem.id}`,
                    method: 'put',
                    data: {...newItem, clientId: selectedClientId}
                });
            }
            await fetchItems();
            handleClose();
        } catch (error) {
            console.error(`Error saving ${itemTitle}:`, error);
            throw error;
        }
    };

    const handleRowClick = (item: Item) => {
        setSelectedItem(item);
        setMode('view');
        setOpen(true);
    };

    const handleModeChange = (newMode: 'add' | 'view' | 'edit') => {
        setMode(newMode);
    };

    const getDisplayValue = (item: Item, column: Column) => {
        // Add status handling
        if (column.id === 'status' && item.status) {
            return <StatusSquare status={item.status} />;
        }

        if (column.dataType === 'date' && item[column.id]) {
            return formatDate(item[column.id]);
        } else if (column.type === 'radio' && column.options) {
            const optionIndex = Number(item[column.id]);
            return column.options[optionIndex] || item[column.id];
        } else if (column.type === 'select' && selectOptions[column.id]) {
            const option = selectOptions[column.id].find(opt => opt.id.toString() === item[column.id]?.toString());
            return option ? (option.brand_name || option.name || item[column.id]) : item[column.id];
        }
        return item[column.id];
    };

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedItems = React.useMemo(() => {
        const comparator = (a: Item, b: Item) => {
            const column = displayColumns.find(col => col.id === orderBy);
            if (!column) return 0;

            const aValue = getDisplayValue(a, column);
            const bValue = getDisplayValue(b, column);

            if (bValue < aValue) {
                return order === 'asc' ? 1 : -1;
            }
            if (bValue > aValue) {
                return order === 'asc' ? -1 : 1;
            }
            return 0;
        };

        return orderBy ? [...items].sort(comparator) : items;
    }, [items, order, orderBy, displayColumns]);

    const handleClientChange = (event: SelectChangeEvent<number>) => {
        const clientId = event.target.value as number;
        setSelectedClientId(clientId);
        const selectedClient = clients.find(client => client.id === clientId);
        setSelectedClientName(selectedClient ? selectedClient.name : '');
    };

    // Memoize the client menu items
    const clientMenuItems = useMemo(() =>
            clients.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                    {client.name}
                </MenuItem>
            )),
        [clients]);

    const getCellAlignment = (columnLabel: string) => {
        return columnLabel === 'Count' || columnLabel === 'Price' ? 'right' : 'left';
    };

    enum DocType {
        Photo = 'Photo',
        Document = 'Document'
    }

    const renderDialog = () => {
        const commonProps = {
            open,
            onClose: handleClose,
            onSave: handleSave,
            itemId: selectedItem?.id,
            mode,
            onModeChange: handleModeChange,
            columns,
            itemTitle,
            clientId: selectedClientId,
            clientName: selectedClientName,
            docType,
            ro
        };

        switch (docType) {
            case DocType.Photo:
                return <ItemDialogPlusPhotosAndNotes {...commonProps} />;
            case DocType.Document:
                // return <ItemDialogPlusDocuments {...commonProps} />;
            default:
                return null;
        }
    };

    return (
        <Box sx={{
            maxWidth: '600px',
            margin: '0 auto 80px', // Changed to auto horizontal margin
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: '12px',
            paddingRight: '12px'
        }}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '20px',
            }}>
                {(!isSingleClient) ? (
                    <FormControl sx={{
                        width: {xs: '90%', sm: '250px'},
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <InputLabel id="client-select-label">Client</InputLabel>
                        <Select
                            labelId="client-select-label"
                            id="client-select"
                            value={selectedClientId}
                            label="Client"
                            onChange={handleClientChange}
                            fullWidth
                        >
                            {clientMenuItems}
                        </Select>
                    </FormControl>
                ) : (
                    selectedClientName && (
                        <Typography variant="h6" sx={{mb: 2, textAlign: 'center'}}>
                            {selectedClientName}
                        </Typography>
                    )
                )}
                {(selectedClientId && !ro) && (
                    <Button
                        variant="contained"
                        onClick={handleOpen}
                        sx={{
                            marginTop: '20px'
                        }}
                    >
                        Add {itemTitle}
                    </Button>
                )}
            </Box>
            {selectedClientId && (
                <TableContainer  component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {displayColumns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={getCellAlignment(column.label)}
                                        sx={{fontWeight: 'bold'}}
                                    >
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={orderBy === column.id ? order : 'asc'}
                                            onClick={() => handleRequestSort(column.id)}
                                        >
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedItems.map((item) => (
                                <TableRow
                                    key={item.id}
                                    onClick={() => handleRowClick(item)}
                                    style={{cursor: 'pointer'}}
                                >
                                    {displayColumns.map((column) => (
                                        <TableCell
                                            align={getCellAlignment(column.label)}
                                            key={column.id}
                                        >
                                            {getDisplayValue(item, column)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {renderDialog()}
        </Box>
    );
};

export default TablePanelNew;