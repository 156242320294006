import React from 'react';
import { Box, Stack, Chip, Typography, DialogTitle } from '@mui/material';
import { CalendarToday, Business, Settings, QrCode } from '@mui/icons-material';
import { numberToCode } from '../Util';

interface BatchHeaderProps {
    batch: any;
    formatDate: (date: string | Date) => string;
}

const BatchHeader: React.FC<BatchHeaderProps> = ({ batch, formatDate }) => {
    return (
        <DialogTitle sx={{ p: 0 }}> {/* Remove default padding */}
            <Box sx={{
                backgroundColor: 'primary.main', // Darker background for better contrast
                p: 3,
                color: 'white'
            }}>
                <Typography
                    variant="h5"
                    sx={{
                        fontWeight: 700,
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    Batch #{batch.id} ● {formatDate(batch.timestamp)}
                </Typography>

                <Stack spacing={1.5}>


                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: 'inherit'
                    }}>
                        <Business fontSize="small" />
                        <Typography variant="subtitle1">
                            {batch.client_name} /  {batch.process_name}
                        </Typography>
                    </Box>


                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mt: 1
                    }}>
                        <QrCode fontSize="small" sx={{ color: 'inherit' }} />
                        <Chip
                            label={`Batch Code: ${numberToCode(batch.id)}`}
                            sx={{
                                fontWeight: 600,
                                fontSize: '0.95rem',
                                px: 1,
                                color: 'primary.main',
                                backgroundColor: 'white',
                                borderColor: 'white'
                            }}
                        />
                    </Box>
                </Stack>
            </Box>
        </DialogTitle>
    );
};

export default BatchHeader;