import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Paper } from '@mui/material';
import useUserStore from './store/userStore';
import OrganicToggle from "./Common/OrganicToggle";

const AccountInfo: React.FC = () => {
    const user = useUserStore((state) => state.user);
    const getGravatarUrl = useUserStore((state) => state.getGravatarUrl);
    const [gravatarUrl, setGravatarUrl] = useState<string>('');

    useEffect(() => {
        getGravatarUrl().then(setGravatarUrl);
    }, [getGravatarUrl]);

    return (
        <Paper elevation={3} sx={{ m: 2, mt: 10,  p: 4, bgcolor: 'grey.100', borderRadius: 2 }}>
            <Box display="flex" flexDirection="column" alignItems="center">
                {gravatarUrl && (
                    <Avatar
                        src={gravatarUrl}
                        alt="User Avatar"
                        sx={{ width: 80, height: 80, mb: 2 }}
                    />
                )}
                <Typography variant="body1">
                    <strong>Name:</strong> {user.given_name} {user.family_name}
                </Typography>
                <Typography variant="body1">
                    <strong>Email:</strong> {user.email}
                </Typography>
                <Typography variant="body1">
                    <strong>Organization:</strong> {user.organization}
                </Typography>
                <OrganicToggle />
            </Box>
        </Paper>
    );
};

export default AccountInfo;